import React from 'react';
import './about.css';
import headshot from '../files/headshot.png'; 

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about-content">
        <div className="about-text">
          <p className="about-intro">Hi, my name is</p>
          <h1 className="about-name">Lily Pham.</h1>
          <h2 className="about-tagline">
            I am passionate about software engineering, finance, and web development.
          </h2>
          <p className="about-description">
            I am a current Senior at Cornell University Studying Computer Science. I am deeply
            involved in using tech for impact through my work on QMI, Millenium Management at my
            school.{' '}
            <a
              href="https://www.linkedin.com/in/lilynpham/"
              target="_blank"
              rel="noopener noreferrer"
              className="about-link"
            >
              Learn more about me!
            </a>
          </p>
          <a href="https://github.com/burninglilies" target="_blank" className="about-button">
            Check out my GitHub!
          </a>
        </div>
        <div className="about-photo">
          <img src={headshot} alt="Lily Pham" className="headshot" />
        </div>
      </div>
    </section>
  );
};

export default About;
