import React from 'react';
import './header.css';

const Header = () => {
  const handleNavClick = (event) => {
    event.preventDefault();
    const target = event.currentTarget;
    setTimeout(() => {
      const sectionId = target.getAttribute('href').substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      target.classList.remove('jump-animation');
    }, 200);
  };
  return (
    <header className='header'>
      <nav className='navbar'>
        <ul className='nav-list'>
          <li>
            <a href='#about' className='nav-item' onClick={handleNavClick}>
              About
            </a>
          </li>
          <li>
            <a href='#experience' className='nav-item' onClick={handleNavClick}>
              Experience
            </a>
          </li>
          <li>
            <a href='#projects' className='nav-item' onClick={handleNavClick}>
              Projects
            </a>
          </li>
          <li>
            <a href='mailto:lnp35@cornell.edu' className='nav-item'>
              Contact
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/11GBWvxFXM6rCWoCbMHAX69XRa7Kdd2XO/view?usp=sharing'
              target='_blank'
              className='nav-resume'
              rel='noopener noreferrer'
            >
              Resume
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
